import React, { useEffect } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import ProfileSlider from '~2-components/ProfileSlider/ProfileSlider';
import { dummyTeamListingData } from "~data/dummyTeamListingData";


const profileSliderEg = `import ProfileSlider from '~2-components/ProfileSlider/ProfileSlider';

<ProfileSlider
    tiles=[] // array of ProfileTiles
/>
`;

const SgProfileSlider = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allProfileSliders = useStaticQuery(
        graphql`
            query SGProfileItems {
                allKontentItemProfileListing {
                    nodes {
                        system {
                            id
                        }
                    }
                }
            }
    `);

    const allSliders = allProfileSliders.allKontentItemProfileListing.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Profile Slider</h1>
                    <h2>How to use</h2>
                    <CodeBlock
                        code={profileSliderEg}
                    />

                    <h2>Example</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <ProfileSlider
                        tiles={dummyTeamListingData}
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent profile sliders</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {allSliders.map((item, i) => {
                        return <ProfileSlider key={i} {...item}/>;
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgProfileSlider;
